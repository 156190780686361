<template>
  <v-container fluid>
    <v-layout style="padding-left: 10px; padding-right: 10px;">
    <v-flex xs6 style="padding-top:16px;">
    <h2 class="text-xl font-weight-bold">Upload Theory/PV Center Here</h2>
    </v-flex>
    <v-flex xs6>
        <v-select
            
          v-model="searchParam.type"
          :items="items"
          item-text="state"
          item-value="abbr"
          label="Select File Type"
          
        ></v-select>
      </v-flex>
    </v-layout>
    <div v-if="searchParam.type" style="text-align: center;">
      <!-- Drag-and-Drop Area -->
      <v-card
        class="d-flex flex-column align-center justify-center pa-5"
        outlined
        tile
        :class="isDragging ? 'dragging-area' : 'default-area'"
        @dragover.prevent
        @dragleave="isDragging = false"
        @drop.prevent="handleDrop"
        @dragenter.prevent="isDragging = true"
      >
        <div class="text-center" style="text-align: center;">
          <img
            :src="require('@/assets/images/drag-and-drop .png')"
            style="height: 100px;"
            alt="Drag and Drop"
          />
          <p v-if="!uploadedFileName" class="grey--text">
            Drag and drop an Excel file here
          </p>
          <p v-else>
            <strong>Uploaded File:</strong> {{ uploadedFileName }}
            <!-- Cross button to delete the file -->
            <v-btn
              class="fab small"
              fab
              small
              dark
              color="indigo"
              @click="deleteFile"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </p>
        </div>
        <input
          type="file"
          accept=".xlsx, .xls, .xlx"
          @change="handleFileUpload"
          ref="fileInput"
          class="d-none"
        />
      </v-card>

      <p
        class="dark--text mt-4"
        v-if="!uploadedFileName"
        style="text-align: center; font-size: 13px;"
      >
        or click the select button to upload a file
      </p>

      <!-- Button to Trigger File Dialog -->
      <v-btn
        v-if="!uploadedFileName"
        class="mt-2"
        color="indigo"
        dark
        @click="triggerFileInput"
        style="width: 320px;"
      >
        <v-icon size="30" color="#fff">upload</v-icon>&nbsp;&nbsp;Select File
      </v-btn>

      <v-btn
        v-if="uploadedFileName"
        class="mt-4"
        color="blue"
        dark
        @click="uploadFile()"
        style="width: 320px;"
      >
        submit
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],
  data() {
    return {
      searchParam: {},
      uploadedFile: null,
      uploadedFileName: "",
      isDragging: false,
      items: [
          { state: 'Theoty Center', abbr: '1' },
          { state: 'Practical/Viva Center', abbr: '2' },
        ]
    };
  },
  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.validateAndSetFile(file);
    },
    handleDrop(event) {
      this.isDragging = false;
      const droppedFile = event.dataTransfer.files[0];
      this.validateAndSetFile(droppedFile);
    },
    validateAndSetFile(file) {
      if (file && this.isValidExcelFile(file)) {
        this.uploadedFile = file;
        this.uploadedFileName = file.name;
        console.log("File selected:", file);
      } else {
        alert("Only Excel files (.xlsx, .xls, .xlx) are allowed.");
      }
    },
    isValidExcelFile(file) {
      const allowedExtensions = ["xlsx", "xls", "xlx"];
      const fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      return allowedExtensions.includes(fileExtension);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    deleteFile() {
      // Reset the file and its name
      this.uploadedFile = null;
      this.uploadedFileName = "";
    },
    uploadFile() {
      let peram = {};
      peram.exam_code = this.searchParam.exam_code;
      peram.exam_year = this.searchParam.year;
      peram.type = this.searchParam.type;
      peram.cen_file = this.uploadedFile;

      console.log("Parameters:", peram); // Check if cen_file exists

      if (this.uploadedFile) {
        this.$store.dispatch("uploadtpvFile", peram);
      } else {
        alert("Please upload a file before submitting.");
      }
    },
  },
};
</script>

<style scoped>
.default-area {
  border: 2px dashed #bdbdbd;
  background-color: #f5f5f5;
  transition: all 0.2s ease-in-out;
}
.dragging-area {
  border: 2px dashed #1976d2;
  background-color: #e3f2fd;
}
</style>
